.bg-top-announcement {
    border-bottom: 1px solid rgba(71, 235, 235, 0.15);
    background: radial-gradient(
            218.19% 111.8% at 0% 0%,
            rgba(71, 235, 235, 0.1) 0%,
            rgba(71, 235, 235, 0.2) 100%
        ),
        #14141f;
  }
  
  .top-announcement-mask {
    mask-image: url(https://refine.ams3.cdn.digitaloceanspaces.com/website/static/assets/hexagon.svg);
    -webkit-mask-image: url(https://refine.ams3.cdn.digitaloceanspaces.com/website/static/assets/hexagon.svg);
    mask-repeat: repeat;
    -webkit-mask-repeat: repeat;
    background: rgba(71, 235, 235, 0.25);
  }
  
  .banner {
    display: flex;
    /*@media (max-width: 1000px) {
        display: none;
    }*/
  }
  
  .gh-link, .gh-link:hover, .gh-link:active, .gh-link:visited, .gh-link:focus {
    text-decoration: none;
    z-index: 9;
  }
  
  @keyframes top-announcement-glow {
    0% {
        opacity: 1;
    }
  
    100% {
        opacity: 0;
    }
  }